import React, { useState, useEffect, useContext } from "react"
import Layout from "../../components/common/Layout"
import Idealline from "../../components/idealline"
import { ClientOnly } from "../../components/common"
import { MetaData } from '../../components/common/meta'
import kartingImage from '../../images/go-karting-driver.jpg'
import OnboardVideos from "../../components/tracks/OnboardVideos"
import Confetti from '../../components/common/Confetti'

const Track = ({ params, data, location, pageContext }) => {
    const {
        ID: Id = "419",
        Name: name = "Portimao",
        Gmaps_Place_Name: place_name = "Autodromo Internacional do Algarve",
        Gmaps_Rating: place_rating = "4.8",
        Gmaps_Place_Id: place_id = "ChIJy4IGtpgkGw0RHiq9k-3ktKY",
        Country: country = "Portugal",
        Url: filename = "portimao",
        Location = "37.233928, -8.635591",
    } = {}
    const lat = +Location.split(',')[0]
    const lng = +Location.split(',')[1]

    const country_path = `/go-karting-tracks/${country.toLowerCase().replace(/ /g, "-")}`

    let formattedName = name
    if (place_name?.toLowerCase().includes(name.toLowerCase())) formattedName = place_name
    else if (place_name && place_name != "") formattedName = name + " - " + place_name

    let title = `Your race at ${formattedName} `
    let description = `Your race at ${formattedName}.`


    return (
        <>
            <MetaData
                title={title}
                description={description}
                location={location}
                image={kartingImage}
            />
            <Layout>
                <article className="post-full {{ page.subclass }} {% unless page.cover %}no-image{% endunless %}">
                    <header className="post-full-header">
                        <h1 className="post-full-title">Thank you 🏁</h1>
                        <p className="my-2">
                            Got your sign-up for the Go Karting Race. You're
                            either locked in on the racing list or queued up on
                            the waitlist. Either way, we've got you covered.
                            Stay tuned for updates!
                        </p>

                        <Confetti />
                    </header>
                    <iframe
                        className="my-4 mb-12"
                        height="500px"
                        width="100%"
                        frameborder="0"
                        allowTransparency="true"
                        scrolling="auto"
                        src="https://creatorapp.zohopublic.eu/karting/karting-check-in/report-embed/Live_Participants_List/Dvb7AjYDKNEU8kqYBAAZUfq7DZy0fq9FHA2YnYpuCWPKkhgjerbUKBxN5JHVjKmxQ5EVVqZYFxMOb5AWVUJRN94fRrPC1Mvu3MJw"
                    ></iframe>
                    <h2 className="text-3xl">{formattedName}</h2>
                    <ClientOnly>
                        <Idealline
                            name={name}
                            placeId={place_id}
                            lat={lat}
                            lng={lng}
                            apikey={process.env.GATSBY_GMAPS_API_KEY}
                            zoom={19}
                            url={"/go-karting-tracks/" + filename}
                        />
                    </ClientOnly>
                    <h1>Onboard Videos</h1>
                    <p>
                        Find onboard videos for {formattedName} by using the
                        search bar to fine tune your Youtube results. For
                        example 'karting', 'rotax' or any other awesome racer 👇
                    </p>
                    <ClientOnly>
                        <OnboardVideos
                            query={`${formattedName} full track karting race`}
                        />
                    </ClientOnly>
                </article>
            </Layout>
        </>
    )
}

export default Track